<template>
  <v-container>
    <v-breadcrumbs :items="items"></v-breadcrumbs>

    <h1 class="display-1 mb-6">Loss Run Report</h1>

    <v-card>
      <v-card-text>
        <v-textarea
          v-model.trim="canonical"
          label="Canonical JSON"
          counter
          maxlength="120000"
          full-width
          filled
          autofocus
          required
          no-resize
          clearable
          height="400"
        ></v-textarea>

        <v-btn
          v-on:click="postCanonical"
          :loading="pdfLoading"
          :disabled="canonical == [] && validJSON == true"
          color="primary"
        >
          <v-icon left>
              mdi-file-pdf
          </v-icon>
          Upload and Download PDF Report
        </v-btn>
      </v-card-text>
    </v-card>

    <v-snackbar v-model="error">
      <v-icon>mdi-alert-circle-outline</v-icon>
      {{ message }}
    </v-snackbar>

  </v-container>
</template>

<script>
import { API } from 'aws-amplify';
import FileSaver from 'file-saver';

export default {
  name: 'LossRun',
  metaInfo: {
    title: 'Loss Run Report',
  },
  data: () => ({
    loading: true,
    validJSON: false,
    // rules: [
    //   value => !!value || 'Required.', // eslint-disable-line
    //   value => JSON.parse(value)|| 'Must be valid JSON', // eslint-disable-line
    // ],
    error: false,
    canonical: [],
    canonicalDisplay: [],
    message: '',
    pdfLoading: false,
    items: [
      {
        text: 'AppHub',
        exact: true,
        to: '/',
      },
      {
        text: 'Reports',
        exact: true,
        to: '/reports',
      },
      {
        text: 'Loss Run Report',
        exact: true,
      },
    ],
  }),
  methods: {
    async postCanonical() {
      this.pdfLoading = true;

      const canonicalData = {
        body: {
          data: JSON.parse(this.canonical),
          template: 'report_templates/loss_run.html',
        },
        headers: {
          Accept: 'application/pdf',
        },
        responseType: 'blob',
      };

      await API.post('AppHubAPI', 'report', canonicalData)
        .then((response) => {
          FileSaver.saveAs(response, 'loss-run-report.pdf');

          this.pdfLoading = false;
        })
        .catch((error) => {
          console.log(error); // eslint-disable-line

          this.error = true;
          this.message = error.message;
          this.pdfLoading = false;
        });
    },
    validJSONF(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        this.validJSON = false;
      }
      this.validJSON = true;
    },
  },
};
</script>
