import { render, staticRenderFns } from "./LossRun.vue?vue&type=template&id=1b04f8a5&"
import script from "./LossRun.vue?vue&type=script&lang=js&"
export * from "./LossRun.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBreadcrumbs,VBtn,VCard,VCardText,VContainer,VIcon,VSnackbar,VTextarea})
